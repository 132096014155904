import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { decode } from "../helpers"
import { createRedirects } from "../utils/RedirectsManager"
import { useFetchGeo } from "../utils/hooks"

// decodes urls before redirecting to LeoVegas.com

const RedirectPage = ({ location }) => {
  const subdivision = useFetchGeo()
  const data = createRedirects(location, subdivision)

  if (data.isSubdivisionListed) {
    navigate(data.redirectUrl)
  } else if (subdivision !== undefined && !data.isSubdivisionListed) {
    if (typeof window !== "undefined") {
      const localhost = "localhost:8000"
      let signUpURL = "https://www.leovegas.com/signup/?"

      let match
      const urlParams = {}

      const search = /([^&=]+)=?([^&]*)/g
      const query = window.location.search.substring(1).replace(/\/$/, "")

      // eslint-disable-next-line no-cond-assign
      while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2])

      const params = new URLSearchParams(window.location.search)

      // eslint-disable-next-line no-restricted-globals
      if (location.host !== localhost) {
        if (query) {
          signUpURL += params.toString()
        }

        window.location.replace(signUpURL)
      }
    }
  }
  return null
}

RedirectPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired
}

export default RedirectPage
